export const apkFileLink = 'https://storage.googleapis.com/noti-aboutness-firebase-48728.appspot.com/app-debug2.3.5x.apk'
export const consentFileLink = 'https://storage.googleapis.com/noti-aboutness-firebase-48728.appspot.com/%E8%AE%8A%E6%9B%B4%E6%A0%B8%E5%AE%9A%E7%89%88_%E5%8F%83%E8%88%87%E8%80%85%E5%90%8C%E6%84%8F%E6%9B%B8V7.pdf'
export const batteryLinkTable = {
  asus: 'https://docs.google.com/document/d/1Bg6TyPAzUXZy9XaIZiNzPyQGroa4nF2Cx3j36Vwix34/edit#heading=h.hw5o2t1m5uc0',
  xiaomi: 'https://docs.google.com/document/d/1Bg6TyPAzUXZy9XaIZiNzPyQGroa4nF2Cx3j36Vwix34/edit#heading=h.ms2a4br3ishv',
  samsung: 'https://docs.google.com/document/d/1Bg6TyPAzUXZy9XaIZiNzPyQGroa4nF2Cx3j36Vwix34/edit#heading=h.dhmyp7e5zs3x',
  sony: 'https://docs.google.com/document/d/1Bg6TyPAzUXZy9XaIZiNzPyQGroa4nF2Cx3j36Vwix34/edit#heading=h.vnfowizn96c',
  vivo: 'https://docs.google.com/document/d/1Bg6TyPAzUXZy9XaIZiNzPyQGroa4nF2Cx3j36Vwix34/edit#heading=h.ul17p3jwpkvr',
  realme: 'https://docs.google.com/document/d/1Bg6TyPAzUXZy9XaIZiNzPyQGroa4nF2Cx3j36Vwix34/edit#heading=h.8niwlr2771ys',
  oppo: 'https://docs.google.com/document/d/1Bg6TyPAzUXZy9XaIZiNzPyQGroa4nF2Cx3j36Vwix34/edit#heading=h.ik7ymn1jpeyi',
  htc: 'https://docs.google.com/document/d/1Bg6TyPAzUXZy9XaIZiNzPyQGroa4nF2Cx3j36Vwix34/edit#heading=h.z2uwii13w0sp'
}
export const esmTutorial = 'https://docs.google.com/document/d/1Bg6TyPAzUXZy9XaIZiNzPyQGroa4nF2Cx3j36Vwix34/edit?usp=sharing'
export const installYoutubeId = 'IjRmTi4qaME'
export const orientationYoutubeId = 'Hiu1z8-9bWg'
export const receiptSample = 'https://storage.googleapis.com/noti-aboutness-firebase-48728.appspot.com/receipt-sample.png'
export const internalApkLink = 'https://notiaboutness.muilab.org/appdl'
export const fullAtThisStage = false
