const INIT = 'init'
const VIDEO_DONE = 'videoDone'
const CONSENT_CHOSEN = 'consentChosen'
const WAIT_FOR_REVERSED = 'waitForReversed'
const CONSENT_SENT = 'consentSent'
const CONSENT_VALID = 'consentValid'
const BIG_FIVE_DONE = 'bigFiveDone'
const APP_VALID = 'appValid'
const READY = APP_VALID
const RESEARCH_RUNNING = 'researchRunning'
const RESEARCH_DONE = 'researchDone'
const SET_RECEIPT_MAIL_METHOD = 'setReceiptMailMethod'
const SET_PAY_METHOD = 'setPayMethod'
const WAIT_FOR_RECEIPT_REVERSED = 'waitForReceiptReversed'
const RECEIPT_CHOSEN = 'receiptChosen'
const PAYMENT_REQUIRED = 'paymentRequired'
const PAYMENT_DONE = 'paymentDone'
const INTERVIEWEE = 'interviewee'
const ALL_DONE = 'allDone'

export default {
  INIT,
  BIG_FIVE_DONE,
  CONSENT_CHOSEN,
  CONSENT_SENT,
  WAIT_FOR_REVERSED,
  CONSENT_VALID,
  VIDEO_DONE,
  APP_VALID,
  READY,
  RESEARCH_RUNNING,
  RESEARCH_DONE,
  SET_RECEIPT_MAIL_METHOD,
  SET_PAY_METHOD,
  WAIT_FOR_RECEIPT_REVERSED,
  RECEIPT_CHOSEN,
  PAYMENT_REQUIRED,
  PAYMENT_DONE,
  INTERVIEWEE,
  ALL_DONE
}
