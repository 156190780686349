const questionList = [
  { text: '我總是聚會上炒熱氣氛的人' },
  { text: '不關心其他人' },
  { text: '總有所準備' },
  { text: '很容易感覺壓力過大' },
  { text: '詞彙豐富' },
  { text: '話不多' },
  { text: '對人感興趣' },
  { text: '把自己的東西到處亂放' },
  { text: '大部分時間很放鬆' },
  { text: '很難理解抽象的概念' },
  { text: '和別人相處時感覺很自然' },
  { text: '容易冒犯別人' },
  { text: '注重細節' },
  { text: '擔心的事多' },
  { text: '有生動的想像力' },
  { text: '躲在人群的背後，不喜歡引人注目' },
  { text: '能同情他人的感受' },
  { text: '常把事情搞得一團糟' },
  { text: '很少感到憂鬱' },
  { text: '對抽象的觀點不感興趣' },
  { text: '主動與別人交談' },
  { text: '對別人遇到的問題不感興趣' },
  { text: '平常的事馬上就處理，不拖延' },
  { text: '很容易心煩' },
  { text: '總有好點子' },
  { text: '不健談' },
  { text: '心很軟' },
  { text: '常忘了將東西放回原處' },
  { text: '很容易不高興' },
  { text: '想像力不豐富' },
  { text: '在聚會上和很多不同的人聊天' },
  { text: '對別人不怎麼感興趣' },
  { text: '喜歡有條理' },
  { text: '情緒變化很大' },
  { text: '理解事物快' },
  { text: '不喜歡被別人注意' },
  { text: '抽空幫助別人' },
  { text: '逃避責任' },
  { text: '常常會心情起伏不定' },
  { text: '使用複雜艱深的詞語' },
  { text: '不介意成為眾人關注的焦點' },
  { text: '能感受別人的情緒' },
  { text: '按計畫做事' },
  { text: '很容易發怒' },
  { text: '花時間反思一些事情' },
  { text: '不愛跟陌生人說話' },
  { text: '讓別人在和我相處時感覺很放鬆' },
  { text: '對工作要求準確無誤' },
  { text: '經常感到憂鬱' },
  { text: '主意很多' }
]

export default questionList
